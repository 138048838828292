import request from '../request'

import { PageParamsType } from '@/routes/data/daily-reports/DailyReport'
import {
  ReportTypeEnum,
  DailyReport
} from '@/routes/manage/report/report.types'
import { SpecialAlert } from '@/routes/data/special-alerts/special-alerts.types'

export const getDailyReport = (params: Omit<PageParamsType, 'currentPage'>) => {
  return request.get<{ dailyReports: DailyReport[]; totalCount: number }>(
    `data/report/daily`,
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params.date && { date: params.date.valueOf() }),
        ...(params.reportId && { reportId: params.reportId })
      }
    }
  )
}
export const getSpecialAlerts = (
  params: Omit<PageParamsType, 'currentPage'>
) => {
  return request.get<{ specialAlerts: SpecialAlert[]; totalCount: number }>(
    `data/report/special-alert`,
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params.date && { date: params.date.valueOf() }),
        ...(params.reportId && { reportId: params.reportId })
      }
    }
  )
}

export const getReportSnapshot = (reportId: string, type: ReportTypeEnum) => {
  return request.get<string>(`data/report/${reportId}/${type}/snapshot`)
}

export const resendDailyReport = (params: {
  reportId: string
  _id: string
}) => {
  return request.post<string>(`data/report/daily/${params.reportId}/resend`, {
    data: {
      _id: params._id
    }
  })
}
