import React, { useState, forwardRef } from 'react'
import {
  Paper,
  Stack,
  Box,
  IconButton,
  Chip,
  PopoverProps,
  Tooltip,
  TextField,
  Button,
  StackProps
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'

import { PaginationParamsType } from '@/configs/config.types'
import { DailyReport as DailyReportType } from '@/routes/manage/report/report.types'

import { resendDailyReport } from '@/service/data/report'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Moment } from 'moment'
import { useAppDispatch, useAppSelector } from '@/store'
import Confirm, { ConfirmType } from '@/components/Confirm'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { useNavigate } from 'react-router-dom'
import AlertList from '@/components/AlertList'
import { grey } from '@mui/material/colors'
import { ReportTypeEnum } from '../report.types'
import EmailSnapshot from '../EmailSnapshot'

import usePageList from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import CopyButton from '@/components/CopyButton'
import { getDailyReports } from '@/service/manage/report'
import OrgGroupSelector from '@/components/OrgGroupSelector'
import ListDatePicker from '@/components/ListDatePicker'
import { ManageAlertListStyle } from '../../manage.const'

type DailyReportListFilterType = {
  date?: Moment
  reportId?: string
  organizationId?: string
  groupId?: string
}

export type PageParamsType = PaginationParamsType & DailyReportListFilterType

interface ManageDailyReportProps extends StackProps {}

const ManageSpecialAlert = forwardRef<typeof Stack, ManageDailyReportProps>(
  ({ ...props }, ref) => {
    const navigate = useNavigate()

    const user = useAppSelector((state) => state.users.user)
    const dispatch = useAppDispatch()

    const [resending, setResending] = useState(false)
    const [confirmParams, setConfirmParams] =
      useState<ConfirmType<'re-send', DailyReportType>>(null)

    const {
      loading,
      filters,
      page,
      limit,
      listData,
      totalCount,
      refresh,
      changePage,
      changeFilters
    } = usePageList<DailyReportListFilterType, DailyReportType>(
      'dailyReports',
      getDailyReports
    )

    const [actionParams, setActionParams] = useState<{
      type: 'snapshot' | 'resend'
      data: DailyReportType
    } | null>(null)

    const sendDailyReport = async () => {
      setResending(true)
      const resp = await resendDailyReport({
        reportId: confirmParams?.data?.reportId,
        _id: confirmParams?.data?._id
      })
      if (resp?.code === 200) {
        setConfirmParams(null)
        dispatch(
          globalSlice.actions.setNotification({
            type: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            desc: 'Daily report has been sent successfully'
          })
        )
      }
      setResending(false)
    }

    const showTreeOnMap = async (treeId: string) => {
      navigate('/map', { state: { navTreeId: treeId } })
    }

    return (
      <>
        <Stack sx={{ height: 1 }} spacing={1}>
          <Stack
            sx={{ flexShrink: 0 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <ListDatePicker
                date={filters?.date}
                onDateChange={(date) => changeFilters({ date })}
              />

              <TextField
                size={'small'}
                sx={{ bgcolor: 'white' }}
                value={filters?.reportId || ''}
                placeholder="Daily Report ID"
                onChange={(evt) =>
                  changeFilters({ reportId: evt.target.value })
                }
              />
              <OrgGroupSelector
                size="small"
                organizationId={filters?.organizationId}
                groupId={filters?.groupId}
                onOrgChange={(organizationId) =>
                  changeFilters({ organizationId })
                }
                onGroupChange={(groupId) => changeFilters({ groupId })}
              />
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Tooltip title={'User Email'} placement="top">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Icons.ContactMail
                      fontSize="small"
                      sx={{ color: 'primary.main' }}
                    />
                    {!user.email ? (
                      <Chip size="small" variant="outlined" label={'Empty'} />
                    ) : (
                      <Chip
                        size="small"
                        variant="outlined"
                        color={'primary'}
                        label={user.email}
                      />
                    )}
                  </Stack>
                </Tooltip>

                {user?.isDefault && (
                  <Tooltip title={'Additional Receipients'} placement="top">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Icons.PeopleOutline
                        fontSize="small"
                        sx={{ color: grey[600] }}
                      />
                      <Box>
                        {!user?.additionalEmails ||
                        user?.additionalEmails?.filter((email) => !!email)
                          ?.length === 0 ? (
                          <Chip
                            size="small"
                            variant="outlined"
                            label={'Empty'}
                          />
                        ) : (
                          user?.additionalEmails?.map((email) => (
                            <Chip size="small" key={email} label={email} />
                          ))
                        )}
                      </Box>
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
              <TipIconBtn tip="Refresh" size="small" onClick={() => refresh()}>
                <Icons.Refresh fontSize="inherit" />
              </TipIconBtn>
            </Stack>
          </Stack>

          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Paper sx={{ height: '100%' }}>
              <DataGridC
                loading={loading}
                getRowId={(row) => row.reportId}
                rows={listData}
                rowCount={totalCount}
                pageSizeOptions={[15]}
                paginationModel={{
                  page: page > 0 ? page - 1 : 0,
                  pageSize: limit
                }}
                onPaginationModelChange={(params) => {
                  const isPageSizeChanged = limit !== params.pageSize
                  changePage({
                    page: isPageSizeChanged ? 1 : params.page + 1,
                    limit: params.pageSize
                  })
                }}
                columns={getColumns({
                  showDailyReportSnapshot: (report) =>
                    setActionParams({ type: 'snapshot', data: report }),
                  onConfirmReSend: (anchor, row) =>
                    setConfirmParams({
                      anchor,
                      type: 're-send',
                      data: row
                    })
                })}
              />
            </Paper>
          </Box>
        </Stack>

        <EmailSnapshot
          open={Boolean(actionParams?.type === 'snapshot')}
          title={
            actionParams?.data?.reportDate &&
            `Daily Report ${actionParams?.data?.reportId}`
          }
          type={ReportTypeEnum.Daily}
          reportId={actionParams?.data?.reportId}
          onClose={() => setActionParams(null)}
        />
        <Confirm
          pending={resending}
          open={Boolean(confirmParams?.anchor)}
          anchorEl={confirmParams?.anchor}
          icon={<Icons.Send />}
          title="Re-send Daily Report"
          message="Are you sure you want to re-send this daily report?"
          onClose={() => setConfirmParams(null)}
          onConfirm={() => sendDailyReport()}
        />
        <AlertList
          sx={ManageAlertListStyle}
          onLocate={(treeId) => showTreeOnMap(treeId)}
        />
      </>
    )
  }
)

export default ManageSpecialAlert

const getColumns: (params?: {
  showDailyReportSnapshot: (row: DailyReportType) => void
  onConfirmReSend: (
    anchor: PopoverProps['anchorEl'],
    row: DailyReportType
  ) => void
}) => GridColDef<DailyReportType>[] = ({
  showDailyReportSnapshot,
  onConfirmReSend
}) => {
  return [
    {
      field: 'reportId',
      headerName: 'Report ID',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell(params) {
        return (
          <Stack direction={'row'}>
            <Button
              color={'primary'}
              onClick={() => showDailyReportSnapshot(params.row)}>
              {params.value}
            </Button>
            <CopyButton size="small" title="Report ID" value={params.value} />
          </Stack>
        )
      }
    },
    {
      field: 'reportDate',
      headerName: 'Date of Report',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD') : '-'
    },
    {
      field: 'organization',
      headerName: 'Organization',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueGetter(params) {
        return params.value?.fullname
      }
    },
    {
      field: 'group',
      headerName: 'Group',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueGetter(params) {
        return params.value?.name
      }
    },
    {
      field: 'totalAlertCount',
      headerName: 'No. of Total Alert',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'disconnectionCount',
      headerName: 'No. of Disconnections',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'tiltingAlertCount',
      headerName: 'No. Angle Alarms',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'vibrationCount',
      headerName: 'No. of Vibrations',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    }
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   width: 120,
    //   renderCell(params) {
    //     return (
    //       <Stack direction="row" spacing={1}>
    //         <TipIconBtn
    //           size="small"
    //           tip="Re-send"
    //           onClick={(event) =>
    //             onConfirmReSend(event.currentTarget, params.row)
    //           }>
    //           <Icons.SendOutlined fontSize="inherit" />
    //         </TipIconBtn>
    //       </Stack>
    //     )
    //   }
    // }
  ]
}
