import { User, NewUser } from '@/routes/manage/user/users.types'
import request from '../request'
import { Group, NewGroup } from '@/routes/setting/users/groups/group.types'
import { TreeItem } from '@/routes/setting/users/groups/ManageTreeList'

export const getUsers = async (params: {
  username?: string
  groupId?: string
  page: number
  limit: number
}) => {
  return request.get<{
    users: User[]
    totalCount: number
  }>('setting/user/users', {
    data: {
      page: params.page,
      limit: params.limit,
      ...(params.username ? { username: params.username } : {}),
      ...(params.groupId ? { groupId: params.groupId } : {})
    }
  })
}

export const createUser = async (params: NewUser) => {
  return request.post<User>('setting/user', { data: params })
}

export const updateUser = async (userId: string, params) => {
  return request.put<User>(`setting/user/${userId}`, { data: params })
}

export const removeUser = async (userId) => {
  return request.delete<User>(`setting/user/${userId}`)
}

export const updateUserAvatar = async (userId, image) => {
  return request.post<{ avatar: string }>(`setting/user/${userId}/avatar`, {
    data: { image }
  })
}

export const changeGroup = async (userId, groupId) => {
  return request.put<User>(`setting/user/${userId}/move-to-group/${groupId}`)
}

export const changePassword = async (
  userId,
  params: { password: string; __v: number }
) => {
  return request.put<User>(`setting/user/${userId}/password`, { data: params })
}

export const getGroups = async () => {
  return request.get<Group[]>(`setting/group`)
}

export const createGroup = async (group: NewGroup) => {
  return request.post<Group>(`setting/group`, { data: group })
}

export const updateGroup = async (groupId: string, group: NewGroup) => {
  return request.put<Group>(`setting/group/${groupId}`, { data: group })
}

export const deleteGroup = async (groupId: string) => {
  return request.delete(`setting/group/${groupId}`)
}

export const getTreesOfGroup = async (
  groupId: string,
  params: {
    treeId?: string
    page: number
    limit: number
    excludesGroup?: string
  }
) => {
  return request.get<{
    trees: TreeItem[]
    totalCount: number
  }>(`setting/group/${groupId}/trees`, {
    data: params
  })
}

export const groupAddTree = async (groupId: string, treeId: string) => {
  return request.put<TreeItem>(`setting/group/${groupId}/add/${treeId}`)
}

export const groupRemoveTree = async (groupId: string, treeId: string) => {
  return request.put<TreeItem>(`setting/group/${groupId}/remove/${treeId}`)
}
