import React, { useState, useRef } from 'react'
import {
  Paper,
  Stack,
  Box,
  IconButton,
  Chip,
  PopoverProps,
  Tooltip,
  TextField,
  Button
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'

import { PaginationParamsType } from '@/configs/config.types'
import {
  DailyReport as DailyReportType,
  ReportTypeEnum
} from '@/routes/manage/report/report.types'
import { getDailyReport, resendDailyReport } from '@/service/data/report'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Moment } from 'moment'
import { useAppDispatch, useAppSelector } from '@/store'
import Confirm, { ConfirmType } from '@/components/Confirm'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { useNavigate } from 'react-router-dom'
import AlertList from '@/components/AlertList'
import { grey } from '@mui/material/colors'
import EmailSnapshot from '../../manage/report/EmailSnapshot'

import usePageList from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import CopyButton from '@/components/CopyButton'
import { PublicAlertListStyle } from '@/routes/public.const'

export type PageParamsType = PaginationParamsType & {
  date: Moment
  reportId?: string
}

const DailyReport = () => {
  const navigate = useNavigate()

  const user = useAppSelector((state) => state.users.user)
  const dispatch = useAppDispatch()

  const [resending, setResending] = useState(false)
  const [confirmParams, setConfirmParams] =
    useState<ConfirmType<'re-send', DailyReportType>>(null)

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters
  } = usePageList<{ date?: Moment; reportId?: string }, DailyReportType>(
    'dailyReports',
    getDailyReport
  )

  const [actionParams, setActionParams] = useState<{
    type: 'snapshot' | 'resend'
    data: DailyReportType
  } | null>(null)

  const sendDailyReport = async () => {
    setResending(true)
    const resp = await resendDailyReport({
      reportId: confirmParams?.data?.reportId,
      _id: confirmParams?.data?._id
    })
    if (resp?.code === 200) {
      setConfirmParams(null)
      dispatch(
        globalSlice.actions.setNotification({
          type: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          desc: 'Daily report has been sent successfully'
        })
      )
    }
    setResending(false)
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/map', { state: { navTreeId: treeId } })
  }

  return (
    <>
      <Stack sx={{ height: 1 }} spacing={1}>
        <Stack
          sx={{ flexShrink: 0 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ backgroundColor: 'white' }}
                format={'DD/MM/YYYY'}
                value={filters?.date}
                onChange={(value, context) => {
                  if (!context.validationError) {
                    changeFilters({ date: value })
                  }
                }}
                slotProps={{
                  inputAdornment: {
                    position: 'start'
                  },
                  textField: {
                    size: 'small',
                    placeholder: 'Date',
                    InputProps: {
                      ...(filters?.date && {
                        endAdornment: (
                          <IconButton
                            size="small"
                            onClick={() => changeFilters({ date: null })}>
                            <Icons.Close fontSize="inherit" />
                          </IconButton>
                        )
                      })
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <TextField
              size={'small'}
              sx={{ bgcolor: 'white' }}
              value={filters?.reportId || ''}
              placeholder="Daily Report ID"
              onChange={(evt) => changeFilters({ reportId: evt.target.value })}
            />
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title={'User Email'} placement="top">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Icons.ContactMail
                    fontSize="small"
                    sx={{ color: 'primary.main' }}
                  />
                  {!user.email ? (
                    <Chip size="small" variant="outlined" label={'Empty'} />
                  ) : (
                    <Chip
                      size="small"
                      variant="outlined"
                      color={'primary'}
                      label={user.email}
                    />
                  )}
                </Stack>
              </Tooltip>

              {user?.isDefault && (
                <Tooltip title={'Additional Receipients'} placement="top">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Icons.PeopleOutline
                      fontSize="small"
                      sx={{ color: grey[600] }}
                    />
                    <Box>
                      {!user?.additionalEmails ||
                      user?.additionalEmails?.filter(
                        (emailAddress) => !!emailAddress
                      )?.length === 0 ? (
                        <Chip size="small" variant="outlined" label={'Empty'} />
                      ) : (
                        user?.additionalEmails?.map((email) => (
                          <Chip size="small" key={email} label={email} />
                        ))
                      )}
                    </Box>
                  </Stack>
                </Tooltip>
              )}
            </Stack>
            <TipIconBtn tip="Refresh" size="small" onClick={() => refresh()}>
              <Icons.Refresh fontSize="inherit" />
            </TipIconBtn>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Paper sx={{ height: '100%' }}>
            <DataGridC
              loading={loading}
              getRowId={(row) => row.reportId}
              rows={listData}
              rowCount={totalCount}
              pageSizeOptions={[15]}
              paginationModel={{
                page: page > 0 ? page - 1 : 0,
                pageSize: limit
              }}
              onPaginationModelChange={(params) => {
                const isPageSizeChanged = limit !== params.pageSize
                changePage({
                  page: isPageSizeChanged ? 1 : params.page + 1,
                  limit: params.pageSize
                })
              }}
              columns={getTreeColumns({
                showDailyReportSnapshot: (report) =>
                  setActionParams({ type: 'snapshot', data: report }),
                onConfirmReSend: (anchor, row) =>
                  setConfirmParams({
                    anchor,
                    type: 're-send',
                    data: row
                  })
              })}
            />
          </Paper>
        </Box>
      </Stack>

      <EmailSnapshot
        open={Boolean(actionParams?.type === 'snapshot')}
        title={
          actionParams?.data?.reportDate &&
          `Daily Report ${actionParams?.data?.reportId}`
        }
        type={ReportTypeEnum.Daily}
        reportId={actionParams?.data?.reportId}
        onClose={() => setActionParams(null)}
      />
      <Confirm
        pending={resending}
        open={Boolean(confirmParams?.anchor)}
        anchorEl={confirmParams?.anchor}
        icon={<Icons.Send />}
        title="Re-send Daily Report"
        message="Are you sure you want to re-send this daily report?"
        onClose={() => setConfirmParams(null)}
        onConfirm={() => sendDailyReport()}
      />
      <AlertList
        sx={PublicAlertListStyle}
        onLocate={(treeId) => showTreeOnMap(treeId)}
      />
    </>
  )
}

export default DailyReport

const getTreeColumns: (params?: {
  showDailyReportSnapshot: (row: DailyReportType) => void
  onConfirmReSend: (
    anchor: PopoverProps['anchorEl'],
    row: DailyReportType
  ) => void
}) => GridColDef<DailyReportType>[] = ({
  showDailyReportSnapshot,
  onConfirmReSend
}) => {
  return [
    {
      field: 'reportId',
      headerName: 'Report ID',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell(params) {
        return (
          <Stack direction={'row'}>
            <Button
              color={'primary'}
              onClick={() => showDailyReportSnapshot(params.row)}>
              {params.value}
            </Button>
            <CopyButton size="small" title="Report ID" value={params.value} />
          </Stack>
        )
      }
    },
    {
      field: 'reportDate',
      headerName: 'Date of Report',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD') : '-'
    },
    {
      field: 'totalAlertCount',
      headerName: 'No. of Total Alert',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'disconnectionCount',
      headerName: 'No. of Disconnections',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'tiltingAlertCount',
      headerName: 'No. Angle Alarms',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'vibrationCount',
      headerName: 'No. of Vibrations',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell(params) {
        return (
          <Stack direction="row" spacing={1}>
            <TipIconBtn
              size="small"
              tip="Re-send"
              onClick={(event) =>
                onConfirmReSend(event.currentTarget, params.row)
              }>
              <Icons.SendOutlined fontSize="inherit" />
            </TipIconBtn>
          </Stack>
        )
      }
    }
  ]
}
